.container {
  margin: 20px 10%;
  background-color: white;
  padding-top: 80px;
}

.site-page-header {
  padding: 20px 10%;
  background-color: #001524;
  color: #e7e7e7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000000;
}

.ant-page-header-heading-extra {
  font-size: 15px;
}

.site-page-header a {
  color: white;
  font-weight: bold;
}

.ant-page-header-heading-title {
  color: white;
}

.col {
  border: 1px solid #e3e3e3;
  padding: 10px 20px;
}